import router from "@/router/index.js";

/**
 * gotoPage
 * @param {string} page
 * @param {object} target
 * @param {object} datas
 * @return {void}
 */
const gotoPage = (page, target, datas) => {
  console.log("gotoPage appelé avec : ", { page, target, datas }); // Log des paramètres d'entrée

  // Si target = "FT", ouvrir l'URL dans un nouvel onglet
  if (target === "FT") {
    window.open(page, "_blank");
    return;
  }

  // Retourner une page en arrière
  if (page === "-1") {
    router.go(-1);
    return;
  }

  // Vérifier la présence de données dans target
  if (target && Object.keys(target).length > 0) { // Vérifie si target existe et n'est pas vide
    console.log("Données présentes :", target);
    
    // Naviguer vers la page avec les données dans la query
    router.push({ path: page, query: target })
      .then(() => {
        // Faire défiler vers le haut après la navigation
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch(err => console.error("Erreur lors de la navigation avec target :", err));
  } else {
    console.log("Aucune donnée trouvée dans target, utilisation de la route sans données.");
    
    // Si seule la route a été donnée
    router.push(page)
      .then(() => {
        // Faire défiler vers le haut après la navigation
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch(err => console.error("Erreur lors de la navigation sans données :", err));
  }
};

export default gotoPage;
