import { toast } from "vue3-toastify";

const showSuccessPopup = (message) => {
  toast.success(message || "Informations sauvegardées avec succès !", {
    position: toast.POSITION.BOTTOM_RIGHT,
  })
};

const showInfoPopup = (message) => {
  toast.info(message || "Informations sauvegardées avec succès !", {
    position: toast.POSITION.BOTTOM_RIGHT,
  })
};

const showInfoPopupCVparser = (message, options = {}) => {
  return toast.info(message || "Informations sauvegardées avec succès !", {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: options.autoClose || false, // Infinite duration by default
  });
};

const showErrorPopup = (message) => {
  toast.error(message || `Une erreur est survenue.`, {
    position: toast.POSITION.BOTTOM_RIGHT,
  })
};

export const toaster = { showSuccessPopup, showErrorPopup, showInfoPopup, showInfoPopupCVparser };