<template>
  <footer class="footer-container">
    <div class="content-wrapper">
      <div class="top-content">
        <div class="logo-container">
            <img @click="gotoPage('/')" src="@/assets/footer/footer-logo.svg" alt="logo" class="photo" />
        </div>
        <div class="sections-container">
            <ul>
                <li @click="gotoPage('/search')">Recherche</li>
                <!--<li @click="gotoPage('/social')">Réseau social</li>-->
                <li @click="gotoPage('/dashboard')">Espace candidat</li>
                <!--<li @click="gotoPage('/search')">Espace recruteur</li>-->
            </ul>
        </div>
        <div class="about-container">
            <ul>
                <li @click="gotoPage('/about')">A propos</li>
                <li @click="gotoPage('/contact')">Contact</li>
                <li @click="gotoPage('/faq')">FAQ</li>
                <li @click="gotoPage('/settings')">Supprimer le compte</li>
                <li data-cc="show-preferencesModal">Gérer ses cookies</li>
            </ul>
        </div>
        <div class="email-n-socials-container">
          <v-form class="email-container" ref="form">
            <p id="CTA_inscription">Inscris-toi à la newsletter</p>
            <v-text-field v-model="formData.email" :rules="[...emailRules, ...notEmptyRules]"
              label="Saisis ton adresse mail" class="email-input">
              <template v-slot:append-inner>
                <button type="submit">
                  <img @click="sendEmail" src="@/assets/footer/footer-sendmail-icon.svg"
                    alt="send mail" class="photo" />
                </button>
              </template>
            </v-text-field>
          </v-form>
          <div class="socials-container">
            <p>Rejoins-nous sur les réseaux</p>
            <div class="socials">
              <img @click="gotoPageExternal('https://www.linkedin.com/company/thanks-boss/')"
                src="@/assets/footer/footer-linkedin-icon.svg" alt="Linkedin" class="photo" />
              <img @click="gotoPageExternal('https://www.tiktok.com/@thanks.boss/')"
                src="@/assets/footer/footer-socialmedia-icon2.svg" alt="send mail" class="photo" />
              <img @click="gotoPageExternal('https://www.facebook.com/people/Thanks-Boss/100063984647627/')"
                src="@/assets/footer/footer-facebook-icon.svg" alt="Facebook" class="photo" />
              <img @click="gotoPageExternal('https://www.instagram.com/thanksboss_off/?fbclid=IwZXh0bgNhZW0CMTAAAR0orVSk0hojBm7EKx8-l0iAUIpepP54013fU0j3TUp31jMmMia94UU9Uyg_aem_3GJVrsD4VT0_J8D3V-mVXA')"
                src="@/assets/footer/footer-instagram-icon.svg" alt="Instagram" class="photo" />
            </div>
          </div>
        </div>
      </div>
      <div class="bot-content">
        <ul>
          <li @click="gotoPage('/mentions-legales')">Mentions légales</li>
          <li @click="gotoPage('/politique-confidentialite')">Politique de confidentialité</li>
          <li @click="gotoPage('/cgu')">Conditions générales d'utilisation</li>
          <li @click="gotoPage('/cgv')">Conditions générales de vente</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import gotoPage from '@/utils/router';
import { submitEmailNewsletter } from '../../../services/newsletter.service';
import { validateEmail, validateNotEmpty } from "../../../utils/validationRules"; 
</script>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            formData: {},
            emailRules: [
                v => validateEmail(v) || true],
            notEmptyRules: [
                v => validateNotEmpty(v) || true],
        };
    },
    methods: {
        gotoPageExternal(url) {
            window.open(url, '_blank');
        },
        async sendEmail() {
            const validate = await this.$refs.form.validate()
            console.log(validate);
            if (validate.valid) {
                try {
                    console.log(this.formData);
                    return await submitEmailNewsletter(this.formData);
                } catch (error) {
                    console.log(error);
                }
            }
        },
    }
};
</script>

<style scoped>
/* tags */


ul {
  list-style: none;
}

li {
  font-size: var(--footer-font-size);
  font-weight: var(--footer-font-weight);
  font-family: var(--footer-font-family);
  font-style: var(--footer-font-style);
  cursor: pointer;
}

.footer-container {
  background-color: var(--navbar-bg-color);
  color: var(--navbar-font-color);
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-wrapper {
  width: 70%;
  height: fit-content;
  margin-top: 40px;
  margin-bottom: 40px;
}

.top-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.photo {
  cursor: pointer;
}

.sections-container {
  min-height: fit-content;
}

/* --> à remettre pour les pages social et recruteur <--
.sections-container ul {
  min-height: 150px;
	display: flex;
	flex-direction: column;
  justify-content: space-between; 
}
*/

/* --> à enlever pour les pages social et recruteur <-- */
.sections-container ul li {
	margin-bottom: 11px;
}

.about-container {
  min-height: fit-content;
}

.about-container ul {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.socials {
  min-width: 200px; /* Réduit la largeur minimale du conteneur des logos */
  display: flex;
  justify-content: space-evenly; /* Ajuste l'espace entre les logos */
  gap: 10px; /* Ajoute cette propriété pour un espacement plus fin entre les logos */
}

.email-container p {
  margin-bottom: 20px; /* Ajuste selon le besoin */
}

.email-input {
  margin-top: 10px; /* Optionnel, pour augmenter l'espace avec le texte */
}

.socials-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre le texte et les icônes */
}

#CTA_inscription{
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.bot-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bot-content ul {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .top-content {
    display: block;
  }

  .top-content ul {
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  }

  .socials-container {
    margin-top: 30px;
  }

  .socials-container p,
  .email-container p {
    margin-bottom: 10px;
  }

  .socials {
    min-width: 50px;
  }

  .bot-content ul {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
  }

  .bot-content li {
    margin-top: 20px;
  }
}

@media screen and (min-width: 2000px) {
  .footer-container {
    padding: 0 7%;
  }
}
</style>
