import { toaster } from "../utils/toast/toast";
import { axiosInstance } from "./axios";

/**
 * submitEmailNewsletter 
 * @param {Object} formData
 * @return {Promise}
 * @throws {Error}
 */
const submitEmailNewsletter = async (formData) => {
  try {
    await axiosInstance.post("/subscriptions/", formData);
    return toaster.showSuccessPopup("Inscription bien prise en compte !.");
  } catch (error) {
    console.error(error);
    if(error.response.status === 400) {
      return toaster.showErrorPopup("Vous êtes déjà inscrit à la newsletter.");
    }
    return toaster.showErrorPopup("Une erreur est survenue.");
  }
};

const activate_newletter = async () => {
  try {
    const response = await axiosInstance.post('/user/activate-newsletter/');
    return response.data;
  } catch (error) {
      console.error('Erreur lors de l activation de la visibilité:', error);
      return [];
  }
};


const desactivate_newletter = async () => {
  try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.post('/user/desactivate-newsletter/',{
          headers: {
              Authorization: `Bearer ${token}`
          }}
      );
      return response.data;
  } catch (error) {
      console.error('Erreur lors de l activation de la visibilité:', error);
      return [];
  }
};


export { submitEmailNewsletter,activate_newletter,desactivate_newletter };

