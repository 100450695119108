import { axiosInstance, getRefreshToken, setAccessToken, setRefreshToken } from './axios';
import store from '@/store';
import { decodeCredential } from 'vue3-google-login';
import { googleOneTap } from 'vue3-google-login';
import { toaster } from '../utils/toast/toast';

/**
Login user by api
@param {Object} data
@param {Boolean} rememberMe
@return {Promise}
@throws {Error}*/
const login = async (data, rememberMe) => {
  try {
    const response = await axiosInstance.post('/user/token/', data);

    const accessToken = response.data.access;
    const refreshToken = response.data.refresh;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    await store.dispatch('fetchUser');
    return;
  } catch (error) {
    throw new Error(error.response.data?.detail || 'Login failed');
  }
};
/**
 * Ask for a new access token using the refresh token
 * @returns {Promise} {newAccessToken: string}
 * @throws {Error}
 */
export const refreshAccessToken = async () => {
  try {
    console.log("access token was expired, trying to refresh it");
    const response = await axiosInstance.post("/user/refresh-token/", {
      refresh: getRefreshToken()
    });
    setAccessToken(response.data.access);
    return {"newAccessToken": response.data.access};
  } catch (error) {
    throw new Error(error.response.data?.detail || 'Refresh token failed');
  }
}

/**
 * Check if user access token exist and if it is valid
 */
const checkAuthStatus = async () => {
  try {
    const token = localStorage.getItem('access_token');
    if (!token) {
      return false;
    }

    // Decode and verify JWT token
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
      return false; // Token don't have a valid format
    }

    const payload = JSON.parse(atob(tokenParts[1]));
    const expirationTime = payload.exp * 1000; // Convert to milliseconds

    // If the token has expired, try to renew it with the refresh token
    if (Date.now() >= expirationTime) {
      try {
        const { newAccessToken } = await refreshAccessToken();
        return !!newAccessToken; // Return true if a new token is obtained
      } catch (refreshError) {
        localStorage.removeItem('access_token');
        document.cookie = 'refresh_token=; path=/; secure; samesite=strict; max-age=0';
        return false;
      }
    }
    return true;
  } catch (error) {
    console.error('Error checking auth status:', error);
    return false;
  }
};

/**
Login user by google one tap
@returns {Promise}
@throws {Error}*/
const googleLogin = async () => {
  try {
    const response = await googleOneTap({
      clientId:
        '499109195466-3cgb1ucg8oon8ncdemjpveuqkv7n0i61.apps.googleusercontent.com',
      context: 'signin',
    });

    const regData = decodeCredential(response.credential);

    // try to login
    const loginResponse = await login({
      email: regData.email,
      password: regData.sub,
    });
    // save token
    // TODO: remove this, tokens are already saved in the login function

    /* setAccessToken(loginResponse.data.access);
    setRefreshToken(loginResponse.data.refresh); */

    // get user profile, and store it in vuex
    addUserToStore();
    return;
  } catch (error) {
    throw new Error(error.response.data.detail || 'Login failed');
  }
};

/**
 
Register user
@param {Object} credentials
@returns {Promise}*/
const register = async (credentials) => {
  try {
    return await axiosInstance.post('/user/register/', credentials, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    // Récupérez le message d'erreur approprié
    const message =
      error.response && error.response.data
        ? error.response.data.detail || "Erreur lors de l'inscription"
        : 'Erreur de connexion au serveur';
    throw new Error(message); // Lancez une erreur avec un message de type chaîne
  }
};
/**
 * Logout user
 */
const logout = async () => {
  try {
    await axiosInstance.post('/user/log-out/');
  } catch (error) {
    console.error('Error during logout:', error);
  } finally {
    store.dispatch('logout');
    localStorage.removeItem('access_token');
    localStorage.removeItem('isLoggedIn');
    document.cookie = 'refresh_token=; path=/; secure; samesite=strict; max-age=0';
    window.location.href = '/';
  }
}

const deleteAccount = async () => {
  try {
    toaster.showInfoPopup(
      'Votre compte est en cours de suppression. Vous allez être redirigé vers la page de connexion.'
    );

    await axiosInstance.delete('/user/');

    window.location.href = '/';

    return;
  } catch (error) {
    toaster.showErrorPopup();
    console.error('Error deleting user account:', error);
  }
};

// à deplacer dans un fichier user.js
const getUser = async () => {
  try {
    const response = await axiosInstance.get('/user/');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.detail || 'User not found');
  }
};
const getUserById = async (id) => {
  try {
    const response = await axiosInstance.get(`/user/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.detail || 'User not found');
  }
};
const getUsersByIds = async (userIds) => {
  if (!userIds.length) return []; // Si le tableau est vide, retourner un tableau vide

  try {
    const userRequests = userIds.map((id) =>
      axiosInstance.get(`/user/${id}`).catch((error) => ({ error }))
    ); // Capturez les erreurs individuellement
    const usersResponses = await Promise.all(userRequests);

    return usersResponses
      .map((response) => (response.error ? null : response.data))
      .filter((user) => user); // Ignorez les réponses nulles
  } catch (error) {
    console.error('Erreur lors de la récupération des utilisateurs : ', error);
    throw error; // Propager l'erreur
  }
};

const addUserToStore = async () => {
  try {
    // Verify if user is already in store
    const user = store.getters['getUser'];
    if (user && user.id && user.email) {
      return;
    }

    // If user is not in store, get it and save it
    const userData = await getUser();
    if (!userData || !userData.type_user) {
      return;
    }
    store.dispatch('handleUserChange', { type: null, payload: userData });
    store.dispatch('handleUserRoleChange', userData.type_user);
  } catch {
    console.error('Error adding user to store:', error);
  }
};

export {
  checkAuthStatus,
  addUserToStore,
  deleteAccount,
  getUser,
  getUserById,
  getUsersByIds,
  googleLogin,
  login,
  logout,
  register,
};
