import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import createStore from "./store";

import { ToastContainer } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import Cookie from "./utils/cookie/Cookie";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
});

// import VueGtaf for google analytics
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(createStore);
app.use(router);
app.use(vuetify);

// cookie consent
app.use(Cookie, {
  onChange: function ({ changedCategories, changedServices }) {
    if (changedServices["analytics"].includes("GoogleAnalytics")) {
      if (CookieConsent.acceptedService("GoogleAnalytics", "analytics")) {
        app.use(VueGtag, {
          config: {
            id: "G-16Z5D1MHNC",
          },
        });
      } else {
        window.location.reload();
      }
    }
  },
  revision: 0,
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {},
  },

  language: {
    default: "fr",
    translations: {
      fr: {
        consentModal: {
          title: "Thanks-boss et les cookies",
          description:
            'Nous utilisons les cookies pour améliorer votre expérience sur notre site web. En cliquant sur "Accepter tout", vous consentez à l\'utilisation de tous les cookies. Vous pouvez également <a href="https://thanks-boss.com/politique-confidentialite" target="_blank">lire notre Politique de Confidentialité</a> et nos <a href="https://thanks-boss.com/cgu" target="_blank">Conditions d\'Utilisation</a>.',
          acceptAllBtn: "Accepter tout",
          acceptNecessaryBtn: "Refuser tout",
          showPreferencesBtn: "Gérer les préférences",
        },
        preferencesModal: {
          title: "Préférences de cookies",
          acceptAllBtn: "Accepter tout",
          acceptNecessaryBtn: "Refuser tout",
          savePreferencesBtn: "Sauvegarder les préférences",
          closeIconLabel: "Fermer la fenêtre",
          sections: [
            {
              title: "Cookies utilisés sur le site",
              description: "",
            },
            {
              title: "Cookies de fonctionnement",
              description:
                "Ces cookies sont nécessaires au bon fonctionnement de notre site web.",
              linkedCategory: "necessary",
            },
            {
              title: "Cookies analytiques",
              description:
                "Ces cookies nous permettent de mesurer le trafic et d'analyser votre comportement sur notre site web. Ces cookies sont utilisés pour améliorer votre expérience utilisateur.",
              linkedCategory: "analytics",
            },
          ],
        },
      },
    },
  },
});
app.component("ToastContainer", ToastContainer);
app.mount("#app");
// TODO : check if this is needed because we take user data already in App.vue
// createStore.dispatch('fetchUser')
