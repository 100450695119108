export default {
  namespaced: true,
  state: {
    subscriptions: [],
    activeSubscription: null,
  },
  mutations: {
    setSubscription(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    setActiveSubscription(state, subscription) {
      console.log("Mutation setActiveSubscription appelée avec:", subscription);
      state.activeSubscription = subscription;
    },
    updateSubscription(state, { type, payload }) {
      const index = state.subscriptions.findIndex((sub) => sub.type === type);
      if (index !== -1) {
        state.subscriptions[index] = {
          ...state.subscriptions[index],
          ...payload,
        };
      } else {
        state.subscriptions.push(payload);
      }
    },
    resetSubscription(state) {
      state.subscriptions = [];
      state.activeSubscription = null;
    },
    deleteSubscriptionProperties(state, { type, payload }) {
      state.subscriptions = state.subscriptions.filter(
        (sub) => sub.type !== type || sub.id !== payload.id
      );
    },
    set_facture(state, facture) {
      state.facture = facture;
    },
  },
  actions: {
    handleSubscriptionChange({ commit }, { type, payload }) {
      commit("updateSubscription", { type, payload });
    },
    handleDeleteSubscriptionProperties({ commit }, { type, payload }) {
      commit("deleteSubscriptionProperties", { type, payload });
    },
    async createFacture({ commit }, factureData) {
      try {
        const response = await axiosInstance.post("/api/facture/", factureData);
        commit("set_facture", response.data);
        return response.data;
      } catch (error) {
        console.error("Erreur lors de la création de la facture :", error);
        throw error;
      }
    },
  },
  getters: {
    getSubscription: (state) => state.subscriptions,
    getActiveSubscription: (state) => {
      console.log(
        "Getter getActiveSubscription appelé, état actuel de l'abonnement actif:",
        state.activeSubscription
      );
      return state.activeSubscription;
    },
    getFacture(state) {
      return state.facture;
    },
  },
};
